var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 grid-margin"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"card-col col-xl-3 col-lg-3 col-md-3 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 mr-sm-4 icon-lg"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8697"},attrs:{"to":{
                      path: '/' + this.$store.state.auth.role + '/customers',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countCustomer.startVal,"endVal":_vm.countCustomer.endVal,"duration":_vm.countCustomer.duration}})],1)],1),_vm._m(0)])])]),_c('div',{staticClass:"card-col col-xl-3 col-lg-3 col-md-3 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 mr-sm-4 icon-lg"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8697"},attrs:{"to":{
                      path: '/' + this.$store.state.auth.role + '/agents',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countAgent.startVal,"endVal":_vm.countAgent.endVal,"duration":_vm.countAgent.duration}})],1)],1),_vm._m(1)])])]),_c('div',{staticClass:"card-col col-xl-3 col-lg-3 col-md-3 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 mr-sm-4 icon-lg"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8697"},attrs:{"to":{
                      path: '/' + this.$store.state.auth.role + '/drivers',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countDriver.startVal,"endVal":_vm.countDriver.endVal,"duration":_vm.countDriver.duration}})],1)],1),_vm._m(2)])])]),_c('div',{staticClass:"card-col col-xl-3 col-lg-3 col-md-3 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 mr-sm-4 icon-lg"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8697"},attrs:{"to":{
                      path:
                        '/' +
                        this.$store.state.auth.role +
                        '/help-and-support',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countHelp.startVal,"endVal":_vm.countHelp.endVal,"duration":_vm.countHelp.duration}})],1)],1),_vm._m(3)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Customers")]),_c('div',{staticClass:"fluid-container"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Agents")]),_c('div',{staticClass:"fluid-container"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Drivers")]),_c('div',{staticClass:"fluid-container"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Help & Support")]),_c('div',{staticClass:"fluid-container"})])}]

export { render, staticRenderFns }